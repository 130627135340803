@charset 'UTF-8';
@import './setting/variables';

.GPC0069 {
	max-width:1344px;
	padding:0 30px 0 30px;
	text-align:center;
	&.full {
		max-width:1600px;
		@include screen(custom, min, 1344) {
			.unit-list {
				max-width: 1344px;
				margin: auto;
			}
		}
	}
	@include screen(custom, max, $max-sm) {
		padding:0 24px 0 24px;
	}
	// bg, color 추가
	&.bg-black {
		background-color: #000;
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			color:$color-white;
		}
		.text,
		.bullet-text li {
			color:#f8f8f8;
			&:before{
				background:#f8f8f8;
			}
		}
		.btn-box {
			.link-text {
				color:#f8f8f8;
			}
			.ico-right{
				&:after {
					background: url('/lg5-common-gp/images/common/icons/link-right-light-gray.svg') no-repeat 0 1px;
				}
			}
		}
		.quote-writer-box {
			color:$color-white;
		}
	}
	&.bg-dark-gray {
		background-color: #333;
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			color:$color-white;
		}
		.text,
		.bullet-text li {
			color:#f8f8f8;
			&:before{
				background:#f8f8f8;
			}
		}
		.btn-box {
			.link-text {
				color:#f8f8f8;
			}
			.ico-right{
				&:after {
					background: url('/lg5-common-gp/images/common/icons/link-right-light-gray.svg') no-repeat 0 1px;
				}
			}
		}
		.quote-writer-box {
			color:$color-white;
		}
	}
	&.bg-gray {
		background-color: #f8f8f8;
	}
	&.bg-white {
		background-color: #fff;
	}
	// bg, color 추가
	// variation
	.unit-list {
		// layout
		.bullet-text {
			width:80%;
			margin-left:auto;
			margin-right:auto;
		}
		// 20200409 START 이상현 - GPC0069 : align-left 옵션의 rtl 수정.
		&.align-left {
			.bullet-text {
				margin-left:0;
				margin-right:0;
			}
			[dir="rtl"] & {text-align:right;}
		} 
		// 20200409 END
		&.block1 {
			max-width:848px;
			margin:0 auto;
			.unit-box {width:100%;}
			.bullet-text {
				width:50%;
				margin-left:auto;
				margin-right:auto;
				@include screen(custom, max, $max-md) {
					width:80%;
				}
			}
			&.align-left .bullet-text {
				margin-left:0;
				margin-right:0;
			}
		}
		&.block2{
			.unit-box {
				width:calc(100%/2 - 12px);
				margin-right:24px;
				//margin-bottom:93px;
				@include screen(custom, max, $max-sm) {
					width:100%;
					margin-right:0;
					//margin-bottom:84px;
				}
				&:last-child {
					margin-right:0;
				}
				ul {
					padding:0;
				}
				[dir="rtl"] & {
					margin-right:0;
					margin-left:24px;
				}
				[dir="rtl"] &:last-child {
					margin-left:0;
				}
			}
		}
		&.block3{
			.unit-box {
				width:calc(100%/3 - 16px);
				margin-right:24px;
				//margin-bottom:93px;
				@include screen(custom, max, $max-sm) {
					width:100%;
					margin-right:0;
					//margin-bottom:84px;
				}
				&:last-child {
					margin-right:0;
				}
				ul {
					padding:0;
				}
				[dir="rtl"] & {
					margin-right:0;
					margin-left:24px;
				}
				[dir="rtl"] &:last-child {
					margin-left:0;
				}
			}
		}
	}
	// base
	.unit-list {
		@extend %clearfix;
		.unit-box{
			float:left;
			[dir="rtl"] & {
				float:right;
			}
			@include screen(custom, max, $max-sm) {
				float:none;
				& + .unit-box {
					margin-top:35px;
				}
			}
		}
	}
	.title,
	.title h1,
	.title h2,
	.title h3,
	.title h4,
	.title h5,
	.title h6  {
		@include font-family($font-semibold);
		font-size:32px;
		line-height:40px;
		margin-bottom:36px;
		@include screen(custom, max, $max-sm) {
			font-size:24px;
			line-height:28px;
			margin-bottom:17px;
		}
	}
	/*
	.text-box{
		&:last-child{
			margin-bottom:93px;
			@include screen(custom, max, $max-sm) {
				margin-bottom:84px;
			}
		}
	}*/
	.text {
		font-size:16px;
		line-height:26px;
		color:$color-dimgray;
		margin:26px 0 0 0;
		@include screen(custom, max, $max-sm) {
			margin-top:12px;
			padding-bottom:1px;
			line-height:25px;
			&:first-child{
				margin-top:17px;
			}
		}
	}
	.bullet-text {
		@include screen(custom, max, $max-sm) {
			margin-top:24px;
			padding-bottom:1px;
			line-height:25px;
		}
		margin:26px 0 0 0;
		li{
			position:relative;
			font-size:16px;
			line-height:26px;
			color:$color-dimgray;
			margin:0 0 3px;
			text-align:left;
			padding-left:11px;
			list-style:none;
			[dir="rtl"] & {
				text-align:right;
				padding-left:0;
				padding-right:11px;
			}
			@include screen(custom, max, $max-sm) {
				&:first-child{
					margin-top:17px;
				}
			}
			&:before{
				content:"";
				position:absolute;
				top:12px;
				left:1px;
				width:3px;
				height:3px;
				border-radius:50%;
				background:$bg-dimgray;
			}
			[dir="rtl"] &:before{
				display:none;
			}
			[dir="rtl"] &:after {
				content:"";
				position:absolute;
				top:12px;
				right:1px;
				width:3px;
				height:3px;
				border-radius:50%;
				background:$bg-dimgray;
			}
		}
	}
	.btn-box {
		margin-top:25px;
		//margin-bottom:25px;
	}
	.link-text {
		@extend %uppercase;
		color:$color-nightrider;
		&:after {
			background: url($link-right-nightrider) no-repeat 0 1px; //LGCOMSPEED-6(8th)
		}
		@include screen(custom, max, $max-sm) {
			//margin-top:21px;
		}
	}
	.btn {
		//margin-bottom:10px;
		@include screen(custom, max, $max-sm) {
			//margin-top:21px;
			//margin-bottom:10px;
		}
	}
	.quote-writer-box{
		margin:69px 0 0;
		text-align:center;
		@include screen(custom, max, $max-sm) {
			margin:49px 0 0;
		}
		.quote{
			font-style: italic;
			font-size:28px;
			line-height:34px;
			padding:0 0 17px 0;
			margin-bottom: 0;
			@include screen(custom, max, $max-sm) {
				font-size:22px;
				line-height:26px;
				padding:0 0 13px 0;
			}
		}
		.writer{
			display:inline-block;
			font-size:16px;
			line-height: 23px;
		}
	}


}
